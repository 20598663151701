<template>
  <div class="stats">
    <h1>STATS</h1>
    <div v-if="!loggedIn">
      <div v-if="response == 'error'"> Wrong code </div>
      <input type="password" v-model="pass"/>
      <button @click="checkLogin">Submit</button>
    </div>
    <div v-if="loggedIn">
      <table cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse;">
        <tr>
          <th>Token</th>
          <th>Name</th>
          <th>Email</th>
          <th>Visited</th>
          <th>Activated</th>
          <th>Date Activated</th>
        </tr>
        <tr v-for="(item, index) in displayStats" :key="index">
          <td>{{ item.token }}</td>
          <td>{{ item.fname }} {{ item.lname }}</td>
          <td>{{ item.email }}</td>
          <td>
            <span v-if="item.visited == 'true'">✅</span>
            <span v-else>⚪</span>
          </td>
          <td>
            <span v-if="item.activated == 'true'">✅</span>
            <span v-else>⚪</span>
          </td>
          <td>{{ item.activeDate }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import EventService from '@/services/EventService.js';


export default {
  name: "Stats",
  components: {
  },
  data() {
    return {
      loggedIn: false,
      pass: null,
      nsPass: '12345',
      response: '',
      statData: {
        data: {
          "statsInfo": [
          ],
          "userlist": [
            {
              token: "",
              fname: "",
              lname: "",
              email: ""
            }
          ]
        }
      }
    }
  },
  computed: {
    displayStats() {
      

        //Process the stats
        var stats = this.statData.data;
        
        console.log(stats.userlist);

        if (stats.userlist) {

          var userFinalStats = [];
          stats.userlist.forEach( (val) => {
              
              // console.log(val.token)
              
              var visited = '';
              var activated = '';
              var activeDate = '';

              stats.statsInfo.forEach( (stat) => {
                if (val.token == stat.token) {
                  if (stat.task == 'visited') {
                    visited = 'true'; 
                  }
                  if (stat.task == 'clicked') {
                    activated = 'true';
                    activeDate = stat.date;
                  }
                }
              });

              userFinalStats.push( 
                {
                  token: val.token,
                  fname: val.fname,
                  lname: val.lname,
                  email: val.email,
                  visited: visited,
                  activated: activated,
                  activeDate: activeDate
                }
              );

          });


          return userFinalStats;
        }
        
        return [];

    }
  },
  methods: {
    checkLogin() {
      if (this.pass == this.nsPass ) {
        this.response = 'success';
        this.loggedIn = true;

        this.updateStats();

      } else {
        this.response = 'error';
      }
    },
    updateStats() {
       EventService.getStats()
          .then( response => {
            // console.log(response);
            this.statData = response;
           });
    }

  }
}


</script>

<style scoped>

table {
  width: 1000px;
}
td, th {
  text-align: left;
  padding: 0.2rem 0.4rem;
}

tr:first-child {
  border-bottom: 1px solid rgba(0,0,0,0.5);
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

</style>